import React, { lazy, Suspense, useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./ml-form"
import FallbackLoading from "../loader/loader";
import "./ml.scss"
const SliderSection = lazy(() => import('./slider'))

export default function FullServiceTeam(props) {
  const isSSR = typeof window === "undefined"
  const [isMobile, setMobile] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth > 991) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  },)


  return (
    <span className={props.wrapperClass}>
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-content-wrap">
          <div className="hero-left">
            <div className="hero-superheading"><span>Machine Learning</span></div>
            <div className="hero-heading">Predictions based on 84,000 A/B test results.</div>
            <div className="hero-subheading">
              <p>The prediction engine clusters data from thousands of scraped A/B tests into similar patterns.  It then generates test predictions based on what won for sites like yours. Predictions are continually refined predictions as more data flows in.</p>
            </div>
            <div className="page-anchors"><Link to="/machine-learning#clustering">Clustering</Link> · <Link to="/machine-learning#predictions">Predictions</Link> · <Link to="/machine-learning#reinforcement">Reinforcement</Link></div>
            <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
          </div>
          <div className="hero-right">
            <Image
              sourceFolder={'machine-learning'}
              lazyLoad={"true"}
              alt={"machine learning"}
              fallBackImage={'ml-hero-desktop.webp'}
              imgName={[
                {
                  imageName: "ml-hero-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "ml-hero-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "ml-hero-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={'homepage'}
          lazyLoad={"true"}
          alt={"Social proof"}
          fallBackImage={'2023-social-logos-mobile.webp'}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <section className="how-it-works">
        <div className="row-wrap" id="clustering">
          <div className="row-left">
            <Image
              sourceFolder={'machine-learning'}
              lazyLoad={"true"}
              alt={"clustering"}
              fallBackImage={'ml-clustering-desktop.webp'}
              imgName={[
                {
                  imageName: "ml-clustering-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "ml-clustering-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="row-right">
            <div className="row-heading"><span>Clustering individual</span>  tests results into patterns.</div>
            <div className="row-text">
              <p>Individual test results from the <Link to="/scraper/">A/B test scraper</Link> are clustered into groups with similar patterns. Using multiple results for the same pattern creates more accurate predictions for how that test pattern will perform for you.</p>
            </div>
          </div>
        </div>
        <div className="row-wrap reverse" id="predictions">
          <div className="row-left">
            <div className="row-heading"><span>Predicting What </span> Will Work Based on Results From Sites Like Yours.</div>
            <div className="row-text">
              <p>Predictions are generated by looking at results from sites most like yours. Factors considered include industry, goal type, demographics, country, recency, and page type. Data from sites most similar to your site are given higher weighting when generating predictions.</p>
            </div>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={'machine-learning'}
              lazyLoad={"true"}
              alt={"predictions"}
              fallBackImage={'ml-predicting-desktop.webp'}
              imgName={[
                {
                  imageName: "ml-predicting-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "ml-predicting-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
        <div className="row-wrap" id="reinforcement">
          <div className="row-left">
            <Image
              sourceFolder={'machine-learning'}
              lazyLoad={"true"}
              alt={"reinforcement"}
              fallBackImage={'ml-reinforcment-desktop.webp'}
              imgName={[
                {
                  imageName: "ml-reinforcment-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "ml-reinforcement-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="row-right">
            <div className="row-heading"><span>Reinforcement Learning</span> Uses Test Results to Refine Predictions.</div>
            <div className="row-text">
              <p>Results from run test are fed back into the system to refine predictions.  When a test wins or loses, it provides clues about how your visitors will react to other tests. As test results accumulate the system continuously incorporates those learnings to refine predictions.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="saas-experts">
        <div className="stats-bg">
          <div className="stats-wrap">
            <div className="stat-item gold">
              <div className="stat-value">130,000</div>
              <div className="stat-name">Websites Scraped</div>
            </div>
            <div className="stat-item blue">
              <div className="stat-value">12,000</div>
              <div className="stat-name">New Tests Per Month</div>
            </div>
            <div className="stat-item red">
              <div className="stat-value">461</div>
              <div className="stat-name">Proven Test Patterns</div>
            </div>
          </div>
        </div>
      </section>
      <FormSection />
      {
        !isSSR && (
          <Suspense fallback={<FallbackLoading />}>
            <SliderSection />
          </Suspense>
        )
      }
    </span >
  )
}
