import * as React from "react"
import Layout from "../components/layout2023"
import Seo from "../components/seo2023"
import Team from "../components/machine-learning/ml"

const FullServiceTeam = () => (
  <Layout  nav={'hidden'}>
    <Seo title="Machine Learning" />
    <Team wrapperClass={'prediction-engine full-team ml'}/>
  </Layout> 
)

export default FullServiceTeam
